<template>
  <div>
    <van-search v-model="searchValue" placeholder="姓名搜索" @search="onSearch">
    </van-search>

    <div class="filterBox">
      <van-button
        :type="filterOption === '' ? 'info' : 'default'"
        size="small"
        @click="filterReq('')"
        >全部</van-button
      >
      <van-button
        :type="filterOption === '1' ? 'info' : 'default'"
        size="small"
        @click="filterReq('1')"
        >讲者</van-button
      >
      <van-button
        :type="filterOption === '0' ? 'info' : 'default'"
        size="small"
        @click="filterReq('0')"
        >主持人</van-button
      >
      <van-button
        :type="filterOption === '2' ? 'info' : 'default'"
        size="small"
        @click="filterReq('2')"
        >致辞嘉宾</van-button
      >
    </div>

    <van-index-bar>
      <van-index-anchor
        v-for="t in experts"
        :key="t.Tag"
        :index="t.Tag.toUpperCase()"
      >
        <div class="typeHeader">{{ t.Tag.toUpperCase() }}</div>
        <van-cell
          v-for="(p, index) in t.doctors"
          :key="index"
          clickable
          @click="clickDoctor(p)"
        >
          <template #title>
            <div class="doctorItem">
              <img class="Portrait" :src="p.Img | getPortrait" />
              <div class="doctorText">
                <span class="custom-title">{{ p.Name }}</span>
                <div>{{ p.Hospital }}</div>
              </div>
            </div>
          </template>
        </van-cell>
      </van-index-anchor>
    </van-index-bar>
  </div>
</template>

<script>
import { GetDoctorInfos } from "@/api";
import { Toast } from 'vant';
import Vue from 'vue';

Vue.use(Toast);

export default {
  name: "Specialist",
  data() {
    return {
      experts: [],
      searchValue: "",
      filterOption: "",
    };
  },
  methods: {
    async GetDoctorInfosAsync() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      const r = await GetDoctorInfos({
        Name: this.searchValue,
        Identype: this.filterOption,
      });
      if (r.code === 1) {
        this.experts = r.data;
      } else {
      }
      Toast.clear()
    },
    clickDoctor(doctor) {
      console.log(doctor);
      sessionStorage.DoctorDetail = JSON.stringify(doctor);
      this.$router.push({
        name: "DoctorDetail",
        params: {
          doctorId: doctor.ID,
        },
      });
    },
    onSearch(val) {
      this.searchValue = val;
      this.GetDoctorInfosAsync();
      // console.log(val);
    },
    filterReq(Identype) {
      this.filterOption = Identype;
      this.GetDoctorInfosAsync();
    },
  },
  created() {
    this.GetDoctorInfosAsync();
  },
  filters: {
    getPortrait(v) {
      return v || require("@/assets/img-header.jpg");
    },
  },
};
</script>

<style scoped>
.van-index-bar >>> .van-index-anchor {
  padding: 0;
}
.doctorItem >>> .van-cell {
  width: auto;
}
.typeHeader {
  padding-left: 16px;
  background-color: rgba(235, 237, 241, 1);
  font-weight: 700;
  font-size: 15px;
  color: #333;
}
.doctorItem {
  display: flex;
  align-items: center;
}
.doctorText {
  padding-left: 16px;
}
.doctorText > .custom-title {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.6;
  color: #2d5abf;
}
.doctorText > div {
  margin: 0;
  line-height: 1.8;
  font-size: 15px;
  color: #999;
}
.Portrait {
  display: block;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #eee;
}
.filterBox {
  padding: 10px 12px;
}
.filterBox .van-button {
  margin-right: 10px;
}
</style>